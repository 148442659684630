// i18next-extract-mark-ns-start products-wave-cosimulation

import { WAVE_APPLICATIONS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductWaveCosimulationPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<p>
							In some applications, detailed analysis of the engine plant requires an integrated approach between 3D and
							1D simulations to provide time-resolved analysis of flow phenomena. For example, optimisation of exhaust and
							intake systems and catalytic converters where the details of the 3D shape can affect the distribution of gas species
							and exhaust gas, or the detailed analysis of the effect of complex in-cylinder combustion phenomena.
						</p>
					</Trans>

					{image && (
						<p>
							<GatsbyImage image={image} alt="WAVE-VECTIS CFD Co-simulation" />
							<small>WAVE-VECTIS Co-simulation model setup</small>
						</p>
					)}

					<Trans i18nKey="content_2">
						<p>
							To address these challenges, WAVE provides native co-simulation capability with Realis Simulation's 3D CFD
							code VECTIS, as well as third-party CFD codes (such as Siemens STAR-CD® and ANSYS Fluent®).
							A generic CFD connector is also available allowing customers to implement coupling to their in-house solutions.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{WAVE_APPLICATIONS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductWaveCosimulationPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-wave", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}	
	banner: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE.png" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE_CFD_Cosimulation.png" }) {
		...imageBreaker
	}
}
`;

